<template>
  <div id="page-container">
    <div id="page-content">
      <main>
        <slot />
      </main>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.content-container) {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
  min-height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
  transition: min-height .25s ease;
  .content {
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
    padding: $actionbar-size 0 !important;
  }
}
:deep(.page-title) {
  .logo {
    margin-bottom: 1.5rem;
    svg {
      color: hsl(var(--text));
      font-size: 2rem;
    }
  }
  p {
    margin-top: .5rem !important;
    font-size: 1.1rem;
    font-weight: normal;
  }
}
:deep(.auth-btn) {
  display: flex;
  max-width: 20rem;
  height: 3rem;
  margin: 0 auto;
  padding: 0;
  box-sizing: content-box;
  transition: background-color 0.25s ease-in-out;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  &.email {
    margin-bottom: 0;
  }
  .icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    font-size: 1.2rem;
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  .label {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spinner,
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    font-size: 1rem;
    svg {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.25s ease-in-out;
      width: 1.1rem;
      height: 1.1rem;
    }
  }
  @media (hover: hover) {
    &:hover {
      .arrow {
        svg {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
:deep(.auth-form) {
  width: 25rem;
  max-width: calc(100vw - 4rem);
  input {
    text-align: center;
  }
}
</style>
